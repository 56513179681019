import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import VueAMap from 'vue-amap'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueAMap)
VueAMap.initAMapApiLoader({
  key: '7c5934c5db753675dc9959d318252fce',
  plugin: ['AMap'],
  v: '1.4.4',
  uiVersion: '1.0'
})
Vue.prototype.$apis = "http://website.icrobo.cn:18089/prod-api";//"http://172.16.1.170:18089/prod-api"http://website.icrobo.cn:18089/prod-api http://192.168.0.125:18089/prod-api

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
