<template>
  <div class="page flex-col">
    <div class="section_about flex-col">
      <div class="group_1 flex-col">
        <navbar :activeName="activeName"/>
        <span class="about_title_cls">{{activeName}}</span>
        <div class="section_3 flex-col"></div>
      </div>
    </div>
    <div class="box_16 flex-col">
      <bread :activeName="activeName" subActiveName="新闻动态详情"/>
      <div class="flex-row category-div-cls">
        <div class="category-text-cls" :class="{'active_category':activeCategory===item.id}" v-for="(item,index) in categoryList" :key="index" @click="clickCategory(item)">{{item.name}}</div>
      </div>
      <div class="news-content-div-cls flex-col">
        <div class="flex-col">
          <span class="news_title_cls">{{detailData.title}}</span>
          <span class="new_sub_cls">{{detailData.content}}</span>
          <span class="news_content_cls" v-html="detailData.remark">

          </span>
        </div>
        <div class="pagination-div-cls justify-between">
          <div class="prev_next_div flex-col justify-between">
            <span class="prev_text" v-if="detailData.beforeId" @click="jumpTo(detailData.beforeId)">上一篇</span>
            <span class="next_text" v-if="detailData.afterId" @click="jumpTo(detailData.afterId)">下一篇</span>
          </div>
          <div class="btn_div flex-col" @click="returnList"><span class="text_btn">返回列表</span></div>
        </div>
      </div>
      <bottom :activeName="activeName" style="margin-top:0px;"/>
    </div>
  </div>
</template>
<script>
import Bottom from '../components/bottom.vue';
import Bread from '../components/bread.vue';
import navbar from '../components/navbar.vue';
import { getTypeList,getProductDetail } from '@/api/home'
export default {
  components: { navbar, Bottom, Bread },
  data() {
    return {
      activeName:'新闻动态',
      categoryList:[],
      activeCategory: '公司新闻',
      newsList:[],
      id:'',
      detailData:{}
    };
  },
  filters:{
    filterNull(val){
      if(val){
        return val
      }else{
        return ''
      }
    }
  },
  created(){
    this.id = parseInt(this.$route.query.id)
    this.initData()
  },
  methods: {
    jumpTo(id){
      this.$router.push({
        path: '/news/newsDetail',
        query: {
          id: id
        }
      })
      this.id = id
      this.initData()
    },
    clickCategory(item){
      this.activeCategory = item.id
      this.$router.push({
          path: '/news?id='+item.id
      })
    },
    initData(){
      getTypeList({type:'news'}).then(res => {
        if (res.code!==200) {
          this.$message.error(res.msg)
        } else {
          let categoryList = []
          res.rows.forEach(element => {
            if(element.status==='0'){
              categoryList.push(element)
            }
          });
          this.categoryList= categoryList
        }
      })
      getProductDetail(this.id).then(res => {
        if (res.code!==200) {
          this.$message.error(res.msg)
        } else {
          console.log(res)
          this.activeCategory = parseInt(res.data.contain);
          this.detailData = res.data
          if(this.detailData.seoKey){
            let content = document.querySelector( 'meta[name="keywords"]').getAttribute('content')
            content = content+ this.detailData.seoKey
            document.querySelector('meta[name="keywords"]').setAttribute('content',content)
          }
          if(this.detailData.seoDesc){
            let content = document.querySelector( 'meta[name="description"]').getAttribute('content')
            content = content+ this.detailData.seoDesc
            document.querySelector('meta[name="description"]').setAttribute('content',content)
          }
        }
      })
    },
    returnList(){
      this.$router.push({
          path: '/news'
      })
    }
  }
};
</script>
<style scoped>

.section_about {
  background-image: url(../assets/img/news1.png);
  background-size: 100% 100%;
  height: 480px;
  width: 100%;
}
.group_1 {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 480px;
}
.about_title_cls {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  text-align: center;
  white-space: nowrap;
  line-height: 45px;
  margin: 220px auto 0 auto;
}
.section_3 {
  background-color: rgba(255, 255, 255, 1);
  width: 48px;
  height: 2px;
  margin: 20px auto 156px auto;
}

.category-div-cls{
  width: 70%;
  min-width: 1024px;
  margin: 0 auto;
  margin-top: -50px;
}
.category-text-cls{
  margin: 0 20px;
  text-align: center;
  height: 50px;
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  cursor: pointer;
}
.active_category{
  font-size: 24px;
  border-bottom: #DEBB81 solid 4px;
}


.news-content-div-cls{
  width: 70%;
  min-width: 1024px;
  margin: 0 auto;
  margin-top: 50px;
  background: #fff;
}

.news_title_cls {
  width: 100%;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 28px;
  margin: 20px 0 0 0;
}

.new_sub_cls {
  width: 100%;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 16px;
  text-align: center;
  line-height: 22px;
  margin: 20px 0 0 0;
}

.news_content_cls {
  width: 100%;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  margin: 40px 0 0 0;
}



.pagination-div-cls{
  margin: 50px auto 100px auto;
  box-shadow: inset 0px 1px 0px 0px #d7d7d7;
  width: 100%;
}

.prev_next_div {
  height: 54px;
  margin-top: 30px;
}

.prev_text {
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  cursor: pointer;
}

.next_text {
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 10px;
  cursor: pointer;
}

.btn_div {
  background-color: rgba(222, 187, 129, 1);
  height: 46px;
  margin-top: 38px;
  width: 144px;
}
.text_btn {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 12px 0 0 40px;
  cursor: pointer;
}

</style>