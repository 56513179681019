<template>
  <div class="box_bread flex-row">
    <span class="text_bread">当前位置：</span>
    <span class="text_bread">网站首页</span>
    <img v-if="activeName"
      class="thumbnail_bread"
      referrerpolicy="no-referrer"
      src="../assets/img/iconBread.png"
    />
    <span class="text_bread" v-if="activeName">{{activeName}}</span>
    <img v-if="subActiveName"
      class="thumbnail_bread"
      referrerpolicy="no-referrer"
      src="../assets/img/iconBread.png"
    />
    <span class="text_bread" v-if="subActiveName">{{subActiveName}}</span>
  </div>
</template>
<script>
export default {
  data() {
    return {
        
    }
  },
  props:{
    activeName: {
      type: String,
      default: ''
    },
    subActiveName: {
      type: String,
      default: ''
    }
  },
  methods:{
  }
}
</script>
<style scoped>
.box_bread {
  box-shadow: inset 0px -1px 0px 0px rgba(215, 215, 215, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  min-width: 1450px;
  height: 80px;
  margin: 0 auto;
  justify-content: flex-end;
  padding: 0 15%;
}
.text_bread {
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
  line-height: 80px;
}
.thumbnail_bread {
  width: 8px;
  height: 8px;
  margin: 36px 6px 0 8px;
}
</style>