import request from '@/utils/request'

export function getCompanyData(data) {
  return request({
    url: 'news/content/list',
    method: 'get',
    params: data
  })
}

  export function getTypeList(data) {
    return request({
      url: 'news/column/list',
      method: 'get',
      params: data
    })
  } 
  
  export function getPicList(data) {
    return request({
      url: 'cms/docs/list',
      method: 'get',
      params: data
    })
  } 

  export function getProductDetail(data) {
    return request({
      url: 'news/content/'+data,
      method: 'get',
      data
    })
  } 

  export function buildMsg(data) {
    return request({
      url: 'news/message',
      method: 'post',
      data
    })
  } 

  export function getBrandList(data) {
    return request({
      url: 'news/url/list',
      method: 'get',
      params: data
    })
  } 

  export function statusList(data) {
    return request({
      url: '/market/work/workStatusList',
      method: 'post',
      data
    })
  } 
  
  export function reportTask(data) {
    return request({
      url: '/market/work/reportWork',
      method: 'post',
      data
    })
  } 