<template>
  <div class="page flex-col">
    <div class="section_about flex-col">
      <div class="group_1 flex-col">
        <navbar :activeName="activeName"/>
        <span class="about_title_cls">{{activeName}}</span>
        <div class="section_3 flex-col"></div>
      </div>
    </div>
    <div class="box_16 flex-col">
      <bread :activeName="activeName"/>
      <div class="cooperation-content-cls">
        <div class="section_label flex-row justify-between">
          <img
            class="image_split_left"
            referrerpolicy="no-referrer"
            src="../assets/img/splite.png"
          />
          <div class="text-title-left-cls flex-col justify-between">
            <span class="title-english-text">MODE</span>
            <div class="text-wrapper-cls">
              <span class="title-left-text">合作</span>
              <span class="title-right-text">模式</span>
            </div>
          </div>
          <img
            class="image_split_right"
            referrerpolicy="no-referrer"
            src="../assets/img/splite.png"
          />
        </div>
      </div>
      <div class="cooperation-content-div-cls">
        <img
            class="cooperation_img_cls mgt50 magb50"
            referrerpolicy="no-referrer"
            src="../assets/img/cooperationContent1.png"
          />
        <img
            class="cooperation_img_cls2 mgt50 magb50"
            referrerpolicy="no-referrer"
            src="../assets/img/cooperationContent2.png"
          />
      </div>
      <div class="cooperation-content-cls">
        <div class="section_label flex-row justify-between">
          <img
            class="image_split_left"
            referrerpolicy="no-referrer"
            src="../assets/img/splite.png"
          />
          <div class="text-title-left-cls flex-col justify-between">
            <span class="title-english-text">CONTACT</span>
            <div class="text-wrapper-cls">
              <span class="title-left-text">联系</span>
              <span class="title-right-text">我们</span>
            </div>
          </div>
          <img
            class="image_split_right"
            referrerpolicy="no-referrer"
            src="../assets/img/splite.png"
          />
        </div>
      </div>
      <div class="cooperation-content-div-cls flex-row justify-between">
        <div class="position-div-cls flex-col justify-between">
          <div class="text-wrapper-border-cls flex-col"><span class="text_cooperation_title">苏州加睿博智能科技有限公司</span></div>
          <div class="text-wrapper-pos-content-cls flex-row">
            <img
              class="image_11"
              referrerpolicy="no-referrer"
              src="../assets/img/cooperationIcon1.png"
            />
            <div class="text-wrapper-pos-cls flex-col justify-between">
              <span class="text_cooperation_content">地址</span>
              <span class="text_cooperation_desc">江苏省苏州市相城区高铁新城南天成路99号清华紫光大厦5楼</span>
            </div>
          </div>
          <div class="text-wrapper-pos-content-cls flex-row">
            <div class="box-pos-content-cls flex-row">
              <img
                class="image_12"
                referrerpolicy="no-referrer"
                src="../assets/img/cooperationIcon2.png"
              />
              <div class="text-wrapper-pos-cls flex-col justify-between">
                <span class="text_cooperation_content">电话</span>
                <span class="text_cooperation_desc">181 1228 5505</span>
              </div>
            </div>
            <div class="box-pos-content-cls flex-row">
              <img
                class="image_12"
                referrerpolicy="no-referrer"
                src="../assets/img/cooperationIcon3.png"
              />
              <div class="text-wrapper-pos-cls flex-col justify-between">
                <span class="text_cooperation_content">邮箱</span>
                <span class="text_cooperation_desc">shiyuexiang@chinamingnuo.com</span>
              </div>
            </div>
          </div>
        </div>
        <div class="map-div-cls">
          <el-amap :zoom="13" :center="center" style="width: 100%; height: 348px;"></el-amap>
        </div>
      </div>
      <div class="cooperation-content-div-cls">
        <div class="section_mesg flex-col justify-between">
          <span class="mesg_text">在线留言</span>
          <div class="mesg_border flex-col"></div>
        </div>
        <el-form ref="msgForm" :model="form" :rules="rules" label-position="top" label-width="140px">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="姓名：" prop="name" required>
                <el-input v-model="form.name" class="input-style" placeholder="请输入您的姓名" style="width:100%" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="电话：" prop="tel" required>
                <el-input v-model="form.tel" class="input-style" placeholder="请输入您的电话" style="width:100%" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="城市：" prop="city" required>
                <el-input v-model="form.city" class="input-style" placeholder="请输入您的城市" style="width:100%" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="预估量：" prop="orderNum">
                <el-input v-model="form.orderNum" class="input-style" placeholder="请输入您所需的预估量" style="width:100%" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="锂电池型号：" prop="productType">
                <el-input v-model="form.productType" class="input-style" placeholder="请输入您所需的锂电池型号" style="width:100%" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="留言内容：" prop="remark">
                <el-input v-model="form.remark" type="textarea" rows=8 class="input-style" placeholder="请输入您的留言" style="width:100%" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="cooperation-btn-div-cls">
          <el-button class="cooperation-btn-cls" @click="submitMsg">提交</el-button>
        </div>
      </div>
      <bottom :activeName="activeName"/>
    </div>
  </div>
</template>
<script>
import Bottom from '../components/bottom.vue';
import Bread from '../components/bread.vue';
import navbar from '../components/navbar.vue';
import { buildMsg } from '@/api/home'
export default {
  components: { navbar, Bottom, Bread },
  data() {
    var checkChar = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入必填项'));
      }else if (/^(?![^\s]+$)(?![^\s]*$)/.test(value)) {
        callback(new Error('不能输入特殊字符'));
      } else {
        callback();
      }
    };
    return {
      activeName: '合作招商',
      center:[120.64545035, 31.4191691],
      rules:{
        name: [{ required: true, validator:checkChar, trigger: 'blur' }],
        tel: [{ required: true, pattern: /^([1-9]\d{10})$/, message: "请输入11位电话号码", trigger: 'blur' }],
        city: [{ required: true, validator:checkChar, trigger: 'blur' }],
      },
      form:{
        name:'',
        tel:'',
        city:''
      }
    };
  },
  methods: {
    submitMsg(){
      this.$refs['msgForm'].validate((valid) => {
        if (valid) {
          this.loading2 = true
          buildMsg(this.form).then((res) => {
            this.loading2 = false
            if (res.code === 200) {
              this.$message.success('提交成功')
              this.$refs['msgForm'].resetFields()
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    }
  }
};
</script>
<style scoped>

.section_about {
  background-image: url(../assets/img/cooperation1.png);
  background-size: 100% 100%;
  height: 480px;
  width: 100%;
}
.group_1 {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 480px;
}
.about_title_cls {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  text-align: center;
  white-space: nowrap;
  line-height: 45px;
  margin: 220px auto 0 auto;
}
.section_3 {
  background-color: rgba(255, 255, 255, 1);
  width: 48px;
  height: 2px;
  margin: 20px auto 156px auto;
}


.mgt50{
  margin-top:50px;
}
.magb50{
  margin-bottom: 50px;
}
.cooperation-content-cls{
  width: 100%;
}
.section_label {
  width: 248px;
  height: 78px;
  margin: 80px auto 0 auto;
}
.image_split_left {
  width: 30px;
  height: 1px;
  margin-top: 55px;
}
.image_split_right {
  width: 30px;
  height: 1px;
  margin: 55px 0 0 30px;
}
.text-title-left-cls {
  margin-left: 30px;
}
.title-english-text {
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 24px;
  text-align: center;
  white-space: nowrap;
  line-height: 33px;
  margin-left: 2px;
}
.title-left-text {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(207, 162, 94, 1);
  font-size: 32px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 45px;
}
.title-right-text {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 32px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 45px;
}
.text-wrapper-cls {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 45px;
  margin-left: 1px;
}

.cooperation-content-div-cls{
  width: 70%;
  min-width: 1024px;
  margin: 0 auto;
  margin-top: 50px;
}
.cooperation_img_cls{
  width: 100%;
}
.cooperation_img_cls2{
  width: 90%;
  margin-left: 5%;
}


.text_cooperation_title {
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 32px;
  font-weight: NaN;
  text-align: left;
  white-space: nowrap;
  line-height: 45px;
}
.text_cooperation_content {
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
}
.text_cooperation_desc {
  width: 89px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 21px;
}
.text-wrapper-pos-cls {
  margin: 0 121px 0 20px;
}
.text-wrapper-border-cls {
  box-shadow: inset 0px -1px 0px 0px rgba(234, 234, 234, 1);
  background-color: rgba(255, 255, 255, 1);
  height: 70px;
  width: 100%;
}
.text-wrapper-pos-content-cls{
  box-shadow: inset 0px -1px 0px 0px rgba(234, 234, 234, 1);
  background-color: rgba(255, 255, 255, 1);
  padding-bottom:30px;
  width: 100%;
}
.position-div-cls{
  width:65%;
  padding-right: 50px;
}
.map-div-cls{
  width:35%;
}
.box-pos-content-cls{
  width:50%;
}

/* 在线留言 */
.section_mesg {
  box-shadow: inset 0px -1px 0px 0px rgba(234, 234, 234, 1);
  background-color: rgba(255, 255, 255, 1);
  margin: 60px 0 0 0;
}
.mesg_text {
  width: 96px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 33px;
}
.mesg_border {
  background-color: rgba(207, 162, 94, 1);
  width: 96px;
  height: 2px;
  margin-top: 18px;
}
.cooperation-btn-div-cls{
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.cooperation-btn-cls{
  width: 152px;
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #DEBB81;
  line-height: 22px;
  border-radius: 4px;
  background: #2B2A28;
  margin: 0 auto;
}
</style>