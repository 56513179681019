import axios from 'axios'
import { Message } from 'element-ui'

// create an axios instance
const service = axios.create({
  baseURL: "http://website.icrobo.cn:18089/prod-api", //'http://website.icrobo.cn:18089/prod-api' http://192.168.0.125:18089/prod-api
  timeout: 60000
})

service.interceptors.request.use(
  config => {
    if (config.data) {
      if (typeof (config.isTrim) === 'undefined') {
        const data = Object.entries(config.data)
        const dataArray = {}
        data.forEach(function (item) {
          dataArray[item[0]] = (typeof (item[1]) === 'string') ? (item[1].trim()) : item[1]
        })
        dataArray.belong = '车辆'
        config.data = dataArray
      }
    }if (config.params) {
      if (typeof (config.isTrim) === 'undefined') {
        const data = Object.entries(config.params)
        const dataArray = {}
        data.forEach(function (item) {
          dataArray[item[0]] = (typeof (item[1]) === 'string') ? (item[1].trim()) : item[1]
        })
        dataArray.belong = '车辆'
        config.params = dataArray
      }
    }
    config.headers['userAgent'] = 'page'
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data;
    if (!res.status) {
      return res
    } else {
      return res
    }
  },
  error => {
    if(!error.message){
      error.message = "操作失败！"
    }
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service
