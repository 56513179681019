import Vue from 'vue'
import VueRouter from 'vue-router'
import productServiceDetail from '../views/productServiceDetail.vue'
import solution from '../views/solution.vue'
import productService from '../views/productService.vue'
import about from '../views/about.vue'
import cooperation from '../views/cooperation.vue'
import index from '../views/index.vue'
import newsDetail from '../views/newsDetail.vue'
import news from '../views/news.vue'
import { getTypeList } from '@/api/home'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/index"
  },
  {
    path: '/productService/productServiceDetail',
    name: 'productServiceDetail',
    component: productServiceDetail,
    meta:{
      title:'产品详情',
      keywords:'产品详情',
      description: '产品详情'
    }
  },
  {
    path: '/solution',
    name: 'solution',
    component: solution,
    meta:{
      title:'解决方案',
      keywords:'解决方案',
      description: '解决方案'
    }
  },
  {
    path: '/productService',
    name: 'productService',
    component: productService,
    meta:{
      title:'产品与服务',
      keywords:'产品与服务',
      description: '产品与服务'
    }
  },
  {
    path: '/about',
    name: 'about',
    component: about,
    meta:{
      title:'关于我们',
      keywords:'关于我们',
      description: '关于我们'
    }
  },
  {
    path: '/cooperation',
    name: 'cooperation',
    component: cooperation,
    meta:{
      title:'合作招商',
      keywords:'合作招商',
      description: '合作招商'
    }
  },
  {
    path: '/index',
    name: 'index',
    component: index,
    meta:{
      title:'苏州加睿博智能科技有限公司',
      keywords:'加睿博官网首页',
      description: '加睿博官网加睿博首页'
    }
  },
  {
    path: '/news/newsDetail',
    name: 'newsDetail',
    component: newsDetail,
    meta:{
      title:'新闻动态',
      keywords:'新闻动态',
      description: '新闻动态'
    }
  },
  {
    path: '/news',
    name: 'news',
    component: news,
    meta:{
      title:'新闻详情',
      keywords:'新闻详情',
      description: '新闻详情'
    }
  }
]

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  getTypeList({type:'company',status:0,name:'公司相关介绍'}).then(res => {
    if (res.code!==200) {
      this.$message.error(res.msg)
    } else {
      if(Object.keys(to.meta).length>0 && to.matched.length>0){
        let this_meta=to.matched[to.matched.length-1].meta
        if(this_meta.title)document.title=this_meta.title
        let head = document.getElementsByTagName('head');
        let meta_keyword=document.createElement('meta');
        if(document.querySelector( 'meta[name="keywords"]')){
          document.querySelector('meta[name="keywords"]').setAttribute('content',this_meta.keywords)
        }else{
          meta_keyword.setAttribute( "name", "keywords")
          meta_keyword.setAttribute( 'content',this_meta.keywords)
          head[0].appendChild(meta_keyword)
        }
        let meta_description=document.createElement( 'meta');
        if(document.querySelector( 'meta[name="description"]')){
          document.querySelector('meta[name="description"]').setAttribute('content',this_meta.description)
        }else{
          meta_description.setAttribute('name',"description")
          meta_description.setAttribute('content',this_meta.description)
          head[0].appendChild(meta_description)
        }
      }
      let data = res.rows[0]
      if(data.seoKey){
        document.querySelector('meta[name="keywords"]').setAttribute('content',data.seoKey)
      }
      if(data.seoDesc){
        document.querySelector('meta[name="description"]').setAttribute('content',data.seoDesc)
      }
    }
  })
  window.scrollTo(0, 0);
  next()
})
export default router
