<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import { getTypeList } from '@/api/home'

export default {
  created(){
    this.initData()
  },
  methods: {
    initData(){
      getTypeList({type:'company',status:0,name:'公司相关介绍'}).then(res => {
        if (res.code!==200) {
          this.$message.error(res.msg)
        } else {
          let data = res.rows[0]
          console.log(data)
          if(data.seoKey){
            document.querySelector('meta[name="keywords"]').setAttribute('content',data.seoKey)
          }
          if(data.seoDesc){
            document.querySelector('meta[name="description"]').setAttribute('content',data.seoDesc)
          }
        }
      })
    }
    
  }
}
</script>

<style lang="css" src="./assets/common.css">
</style>
