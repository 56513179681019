<template>
  <div class="page flex-col">
    <div class="section_about flex-col">
      <div class="group_1 flex-col">
        <navbar :activeName="activeName"/>
        <span class="about_title_cls">{{activeName}}</span>
        <div class="section_3 flex-col"></div>
      </div>
    </div>
    <div class="box_16 flex-col">
      <bread :activeName="activeName"/>
      <div class="block_about_cls flex-row justify-between">
        <div class="box_about_div_cls flex-col justify-between">
          <div class="text-group_6 flex-col justify-between">
            <span class="about_text_cls">{{company.title | filterNull}}</span>
            <span class="about_desc_cls">{{company.content | filterNull}}</span>
          </div>
          <span class="text_about_cls" v-html="company.remark" />
            <!-- {{company.remark | filterNull}}
          </span> -->
        </div>
        <div class="company_image_cls flex-col" :style="'background-image:url('+baseURL+company.pic+')'"></div>
      </div>
      <div class="block_about_cls flex-row justify-between">
        <div class="brand_image_cls flex-col" :style="'background-image:url('+baseURL+brand.pic+')'"></div>
        <div class="box_about_div_cls flex-col justify-between">
          <div class="brand_div_cls">
            <span class="text_brand_cls" v-if="brand && brand.title">XU&nbsp;JU&nbsp;REN&nbsp;BRAND&nbsp;STORY</span>
          </div>
          <span class="about_text_cls" v-if="brand">{{brand.title | filterNull}}</span>
          <span class="text_about_cls" v-html="brand.remark" />
            <!-- {{brand.remark | filterNull}}
          </span> -->
        </div>
      </div>
      
      <div class="box_future_div flex-col">
        <div class="text-wrapper-future flex-col">
          <span class="text_future">未来前景</span>
          <span class="text_future_item" v-html="future"></span>
          <!-- <span class="text_future_item">嘎嘎好，嘎嘎好，嘎嘎好，嘎嘎好，嘎嘎好，嘎嘎好</span>
          <span class="text_future_item">嘎嘎好，嘎嘎好，嘎嘎好，嘎嘎好，嘎嘎好，嘎嘎好</span>
          <span class="text_future_item">嘎嘎好，嘎嘎好，嘎嘎好，嘎嘎好，嘎嘎好，嘎嘎好</span>
          <span class="text_future_item">嘎嘎好，嘎嘎好，嘎嘎好，嘎嘎好，嘎嘎好，嘎嘎好</span> -->
        </div>
      </div>
      <div class="block_about_cls flex-row justify-between">
        <div class="box_about_div_cls flex-col justify-between">
          <div class="flex-col justify-between" style="margin-bottom:10px">
            <span class="text_brand_cls">MING&nbsp;NUO</span>
          </div>
          <span class="about_text_cls">生产厂商·明诺</span>
          <span class="text_about_cls">
            南通明诺电动科技股份有限公司成立于2004&nbsp;年，总部位于江苏海安，是国内较早研发电动扫地机和电动清扫车的厂家。现公司拥有扫地机生产装配厂区、产品研发中心、新能源环卫设备生产厂区和零配件加工厂区。公司在上海、广州、武汉、青岛等设立全资子公司，并在国内外设有近1000+&nbsp;家服务中心。
            <br />
            <br />
            明诺以精益求精的工作态度和不断创新的精神，使明诺品牌在环卫和保洁行业中树立了良好的形象。明诺坚持走“产、学、研”合作之路，与清华大学、东南大学、中科院及东风股份等高校和研究机构建立战略合作关系,&nbsp;明诺现已获得21&nbsp;项发明专利，30&nbsp;项实用新型专利。
            <br />
            <br />
            目前公司已通过了IS09001&nbsp;质量管理认证、ISO14001&nbsp;环境认证、OHSAS18001&nbsp;职业健康安全认证，产品通过了欧盟CE&nbsp;认证，产品远销50&nbsp;多个国家和地区。
          </span>
        </div>
        <div class="company_image_cls1 flex-col"></div>
      </div>
      <bottom :activeName="activeName"/>
    </div>
  </div>
</template>
<script>
import Bottom from '../components/bottom.vue';
import Bread from '../components/bread.vue';
import navbar from '../components/navbar.vue';
import { getCompanyData } from '@/api/home'
export default {
  components: { navbar, Bottom, Bread },
  data() {
    return {
      baseURL: this.$apis,
      brandDesc:'蓄巨人品牌故事',
      companyDesc: '苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容，苏州加睿博公司介绍内容',
      activeName: '关于我们',
      company:{
        title:'',
        content:'',
        remark:''
      },
      brand:{
        title:'',
        remark:''
      },
      future:''
    };
  },
  created(){
    this.initData()
  },
  filters:{
    filterNull(val){
      if(val){
        return val
      }else{
        return ''
      }
    }
  },
  methods: {
    initData(){
      this.getData({callName:'crobo'},'company')
      this.getData({callName:'xjr'},'brand')
      this.getData({type: 'company',title:'未来前景',status:'0'},'future')
    },
    getData(params,type){
      getCompanyData(params).then(res => {
        if (res.code!==200) {
          this.$message.error(res.msg)
        } else {
          switch(type){
            case 'company':
              if(res.rows)
                this.company = res.rows[0]
                // this.company.remark = this.company.remark.replace(/<[^>]*>/g, '')
              break;
            case 'brand':
              if(res.rows)
                this.brand = res.rows[0]
                // this.brand.remark = this.brand.remark.replace(/<[^>]*>/g, '')
              break;
            case 'future':
              if(res.rows && res.rows.length>0){
                this.future = res.rows[0].remark
              }
              break;
            case 'news':
              if(res.rows){
                this.newsList = []
                res.rows.forEach(item=>{
                  let date = item.createTime.split(" ")
                  item.date = date[0].slice(0,7)
                  item.day = date[0].slice(8,10)
                  this.newsList.push(item)
                })
              }
          }
          
        }
      })
    },
  }
};
</script>
<style scoped>
.company_image_cls {
  background-image: url(../assets/img/about1.png);
  background-size: 100% 100%;
  width: 435px;
  height: 338px;
}
.company_image_cls1 {
  background-image: url(../assets/img/about3.png);
  background-size: 100% 100%;
  width: 435px;
  height: 277px;
  margin-top:100px;
}
.brand_image_cls{
  background-image: url(../assets/img/about2.png);
  background-size: 100% 100%;
  width: 435px;
  height: 338px;
  margin-top: 30px;
}
.brand_div_cls{
  width: 100%;
  height: 70px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.block_about_cls {
  width: 1024px;
  margin: 80px auto 0 auto;
}
.text_brand_cls {
  height: 53px;
  overflow-wrap: break-word;
  color: rgba(234, 234, 234, 1);
  font-size: 44px;
  font-family: AlimamaShuHeiTi-Bold;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 53px;
}
.about_text_cls {
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 28px;
  text-align: left;
  white-space: nowrap;
  line-height: 40px;
}
.text_future {
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 28px;
  text-align: left;
  white-space: nowrap;
  line-height: 40px;
  margin: 60px 0 20px 0;
}
.text_future_item {
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  text-align: left;
  line-height: 22px;
  margin: 15px 0;
}
.text-wrapper-future {
  width: 1024px;
  height: 500px;
  margin: 0 auto;
}
.box_about_div_cls{
  width: 522px;
}
.text_about_cls{
  height: 240px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  margin-top: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.about_desc_cls{
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
  margin-top: 10px;
}
.about_title_cls {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  text-align: center;
  white-space: nowrap;
  line-height: 45px;
  margin: 220px auto 0 auto;
}
.section_3 {
  background-color: rgba(255, 255, 255, 1);
  width: 48px;
  height: 2px;
  margin: 20px auto 156px auto;
}
.section_about {
  background-image: url(../assets/img/about4.png);
  background-size: 100% 100%;
  height: 480px;
  width: 100%;
}
.group_1 {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 480px;
}
.box_future_div {
  background-image: url(../assets/img/about5.png);
  background-size: 100% 100%;
  height: 500px;
  margin-top: 60px;
  width: 100%;
}
</style>