<template>
  <div class="page flex-col">
    <div class="section_about flex-col">
      <div class="group_1 flex-col">
        <navbar :activeName="activeName" />
        <span class="about_title_cls">{{ activeName }}</span>
        <div class="section_3 flex-col"></div>
      </div>
    </div>
    <div class="box_16 flex-col">
      <bread :activeName="activeName" subActiveName="产品详情" />
      <div class="flex-row category-div-cls">
        <div
          class="category-text-cls"
          :class="{ active_category: activeCategory === item.id }"
          v-for="(item, index) in categoryList"
          :key="index"
          @click="clickCategory(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="cooperation-content-div-cls flex-row">
        <div class="product-type-image-cls flex-col justify-between">
          <!-- <img :src="baseURL+detailData.pic" class="product-img-cls" :onerror="showErrorImage"/> -->
          <div class="group_12 flex-col">
            <img
              class="image_product_cls"
              referrerpolicy="no-referrer"
              :src="baseURL + pic"
              :onerror="showErrorImage"
            />
          </div>
          <div class="box_24 flex-row">
            <img
              class="box_img_cls_left"
              src="@/assets/img/left.png"
              v-if="showLeft"
              @click="clickLeft"
            />
            <img
              :src="baseURL + item"
              v-for="(item, index) in picArr"
              :key="index"
              :class="[
                'group_pics',
                'flex-col',
                { 'border-active': item == pic },
              ]"
              :onerror="showErrorImage"
              @mouseover="clickPic(item)"
            />
            <img
              class="box_img_cls_right"
              src="@/assets/img/right.png"
              v-if="showRight"
              @click="clickRight"
            />
          </div>
        </div>
        <div class="product-type-div-cls">
          <div class="text-wrapper_2 flex-col">
            <span class="text_17">{{ detailData.callName | filterNull }}</span>
          </div>
          <div class="text-wrapper_3 flex-col">
            <span class="text_18">{{ detailData.title | filterNull }}</span>
          </div>
          <div class="text-wrapper_4 flex-col">
            <span class="text_19">{{ detailData.content | filterNull }}</span>
          </div>
          <div class="text-wrapper_5 flex-col">
            <span class="text_20" @click="returnList">返回列表</span>
          </div>
        </div>
      </div>
      <div class="cooperation-content-div-cls">
        <div class="section_mesg flex-col justify-between">
          <span class="mesg_text">详细介绍</span>
          <div class="mesg_border flex-col"></div>
        </div>
        <div class="param-div-cls flex-row">
          <div v-html="detailData.remark" style="font-size: 14px;"></div>
          <div
            class="param-list-div-cls flex-row"
            v-for="(item, index) in parameterList"
            :key="index"
          >
            <div class="param-title-div-cls">{{ item.name | filterNull }}</div>
            <div class="param-content-div-cls">
              {{ item.content | filterNull }}
            </div>
          </div>
        </div>
      </div>
      <bottom :activeName="activeName" style="margin-top: 0px" />
    </div>
  </div>
</template>
<script>
import Bottom from "../components/bottom.vue";
import Bread from "../components/bread.vue";
import navbar from "../components/navbar.vue";
import { getTypeList, getProductDetail } from "@/api/home";
export default {
  components: { navbar, Bottom, Bread },
  data() {
    return {
      baseURL: this.$apis,
      activeName: "产品与服务",
      categoryList: [],
      activeCategory: "锂电池",
      parameterList: [],
      id: "",
      detailData: {},
      pic: "",
      picArr: [],
      activePic: 0,
      showRight: false,
      showLeft: false,
      picIndex: 0,
      interval: null,
    };
  },
  computed: {
    showErrorImage() {
      return 'this.src="' + require("@/assets/img/productImg.png") + '"';
    },
  },
  filters: {
    filterNull(val) {
      if (val) {
        return val;
      } else {
        return "";
      }
    },
  },
  created() {
    this.id = parseInt(this.$route.query.id);
    this.initData();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkScroll);
    clearInterval(this.interval);
  },
  methods: {
    clickLeft() {
      this.activePic--;
      if (this.activePic == 0) {
        this.showLeft = false;
      }
      let length = this.detailData.picArr.length;
      if (this.activePic + 4 < length) {
        this.showRight = true;
      }
      this.picArr = this.detailData.picArr.slice(
        this.activePic,
        4 + this.activePic
      );
    },
    clickRight() {
      this.activePic++;
      let length = this.detailData.picArr.length;
      if (this.activePic + 4 == length) {
        this.showRight = false;
      }
      if (this.activePic !== 0) {
        this.showLeft = true;
      }
      this.picArr = this.detailData.picArr.slice(
        this.activePic,
        this.activePic + 4
      );
    },
    clickPic(e) {
      this.pic = e;
    },
    clickCategory(item) {
      this.activeCategory = item.id;
      this.$router.push({
          path: '/productService?id='+item.id
      })
    },
    initData() {
      let that = this;
      function rotationToMove() {
        let length = that.detailData.picArr.length;
        if (that.picIndex == length - 1) {
          that.picIndex = 0;
          that.pic = that.detailData.picArr[that.picIndex];
        } else {
          that.picIndex++;
          that.pic = that.detailData.picArr[that.picIndex];
        }
      }
      getTypeList({ type: "product" }).then((res) => {
        if (res.code !== 200) {
          this.$message.error(res.msg);
        } else {
          let categoryList = [];
          res.rows.forEach((element) => {
            if (element.status === "0") {
              categoryList.push(element);
            }
          });
          this.categoryList = categoryList;
        }
      });
      getProductDetail(this.id).then((res) => {
        if (res.code !== 200) {
          this.$message.error(res.msg);
        } else {
          console.log(res);
          this.activeCategory = parseInt(res.data.contain);
          this.detailData = res.data;
          if (this.detailData.seoKey) {
            let content = document
              .querySelector('meta[name="keywords"]')
              .getAttribute("content");
            content = content + this.detailData.seoKey;
            document
              .querySelector('meta[name="keywords"]')
              .setAttribute("content", content);
          }
          if (this.detailData.seoDesc) {
            let content = document
              .querySelector('meta[name="description"]')
              .getAttribute("content");
            content = content + this.detailData.seoDesc;
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", content);
          }
          if (this.detailData.pics) {
            this.detailData.picArr = this.detailData.pics.split(",");
            let length = this.detailData.picArr.length;
            this.picArr = this.detailData.picArr.slice(0, 4);
            this.pic = this.picArr[0];
            this.showRight = length > 4;
          }

          this.interval = setInterval(rotationToMove, 5000);
        }
      });
    },
    returnList() {
      this.$router.push({
        path: "/productService",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.section_about {
  background-image: url(../assets/img/productService1.png);
  background-size: 100% 100%;
  height: 480px;
  width: 100%;
}
.group_1 {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 480px;
}
.about_title_cls {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  text-align: center;
  white-space: nowrap;
  line-height: 45px;
  margin: 220px auto 0 auto;
}
.section_3 {
  background-color: rgba(255, 255, 255, 1);
  width: 48px;
  height: 2px;
  margin: 20px auto 156px auto;
}

.mgt50 {
  margin-top: 50px;
}
.magb50 {
  margin-bottom: 50px;
}
.cooperation-content-div-cls {
  width: 70%;
  min-width: 1024px;
  margin: 0 auto;
  margin-top: 50px;
}
.cooperation_img_cls {
  width: 100%;
}

.category-div-cls {
  width: 70%;
  min-width: 1024px;
  margin: 0 auto;
  margin-top: -50px;
}
.category-text-cls {
  margin: 0 20px;
  text-align: center;
  height: 50px;
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  cursor: pointer;
}
.active_category {
  font-size: 24px;
  border-bottom: #debb81 solid 4px;
}

.section_mesg {
  box-shadow: inset 0px -1px 0px 0px rgba(234, 234, 234, 1);
  background-color: rgba(255, 255, 255, 1);
  margin: 100px 0 0 0;
}
.mesg_text {
  width: 96px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 33px;
}
.mesg_border {
  background-color: rgba(207, 162, 94, 1);
  width: 95px;
  height: 2px;
  margin-top: 18px;
}
.product-type-image-cls {
  background: #ffffff;
  border-radius: 8px;
  border: 2px solid #eaeaea;
  display: flex;
}
.product-type-div-cls {
  width: calc(100% - 522px);
  padding-left: 50px;
  padding-top: 50px;
}
.product-img-cls {
  width: 90%;
  margin: auto 5%;
}

.text-wrapper_2 {
  box-shadow: inset 0px -1px 0px 0px rgba(222, 187, 129, 1);
  background-color: rgba(255, 255, 255, 1);
  height: 57px;
  width: 415px;
}

.text_17 {
  width: 168px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(207, 162, 94, 1);
  font-size: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 33px;
}

.text-wrapper_3 {
  box-shadow: inset 0px -1px 0px 0px rgba(222, 187, 129, 1);
  background-color: rgba(255, 255, 255, 1);
  height: 48px;
  margin-top: 20px;
  width: 415px;
}

.text_18 {
  width: 140px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}

.text-wrapper_4 {
  box-shadow: inset 0px -1px 0px 0px rgba(222, 187, 129, 1);
  background-color: rgba(255, 255, 255, 1);
  height: 48px;
  margin-top: 20px;
}

.text_19 {
  width: 200px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}

.text-wrapper_5 {
  background-color: rgba(222, 187, 129, 1);
  height: 48px;
  margin-top: 100px;
  width: 148px;
  cursor: pointer;
}

.text_20 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin: 13px 0 0 42px;
}

.param-div-cls {
  flex-wrap: wrap;
  margin: 50px 0;
}
.param-list-div-cls {
  width: 50%;
  border: 1px solid #d7d7d7;
  margin-left: -1px;
  margin-top: -1px;
}
.param-title-div-cls {
  width: 40%;
  height: 100px;
  background: #f7f7f7;
  font-size: 18px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
}
.param-content-div-cls {
  width: 60%;
  height: 100px;
  border-left: 1px solid #d7d7d7;
  font-size: 18px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-type-image-cls {
  width: 400px;
  .group_12 {
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
    // background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/ed967d4e2ba545238c4b128a0c74dd67_mergeImage.png);
    // width: 400px;
    // height: 300px;
  }
  .box_24 {
    width: 394px;
    height: 68px;
    margin: 20px 0 0 3px;
    position: relative;
    .group_pics {
      background-image: url(https://lanhu-dds-backend.oss-cn-beijing.aliyuncs.com/merge_image/imgs/d0197c0a084c468995f2611fd1cd834b_mergeImage.png);
      background-size: 100%;
      width: 91px;
      height: 68px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .box_img_cls_left {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 23px;
    cursor: pointer;
  }
  .box_img_cls_right {
    width: 22px;
    height: 22px;
    position: absolute;
    top: 23px;
    right: 0;
    cursor: pointer;
  }
}
.image_product_cls {
  width: 100%;
  margin: auto 0;
  object-fit: contain;
}
</style>