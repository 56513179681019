<template>
  <div class="page flex-col">
    <div class="section_about flex-col">
      <div class="group_1 flex-col">
        <navbar :activeName="activeName"/>
        <span class="about_title_cls">{{activeName}}</span>
        <div class="section_3 flex-col"></div>
      </div>
    </div>
    <div class="box_16 flex-col">
      <bread :activeName="activeName"/>
      <div class="cooperation-content-div-cls">
        <div class="section_mesg flex-col justify-between">
          <span class="mesg_text">行业背景</span>
          <div class="mesg_border flex-col"></div>
        </div>
        <div class="industry-div-cls">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;随着国家环保政策的出台以及国民环保意识的增强，“低碳生活，绿色出行”已成为广大市民的共识。据资料显示，电动车已经成为我国居民广泛接受的一种便捷化交通代步工具，低成本、环保、便捷是电动车最大优势所在。中国有着4亿多的电动自行车用户，而且还在以每年接近3000万辆的速度继续增长。这其中包括500万左右的快递从业人员和1000万左右的外卖、跑腿、及时配送等从业人员。另一方面，专业的电池维护团队能够提供专业的电池维护，这不仅能够有效延长电池使用寿命，并且还能降低由于消费者不当充电带来的安全风险和意外损失。整体而言，电池租赁业务将高频使用造成的电池早衰和低频使用带来的资源闲置进行了再平衡，这也更加符合资源节约型社会的理念。
        </div>
        <div class="section_mesg flex-col justify-between">
          <span class="mesg_text">锂电池租赁场景</span>
          <div class="mesg_border flex-col" style="width: 168px;"></div>
        </div>
        <div class="grid_sean-div flex-row">
          <div class="sean-div-img-cls flex-col">
            <img class="sean-img-cls" src="../assets/img/solutionBg1.png">
            <span class="sean-title">快递应用场景</span>
            <span class="sean-content-cls"
              >电动车使用频率高，常常因为续航差，而无法进行准时派送</span
            >
          </div>
          <div class="sean-div-img-cls sean-img2 flex-col">
            <img class="sean-img-cls" src="../assets/img/solutionBg2.png">
            <span class="sean-title">外卖应用场景</span>
            <span class="sean-content-cls"
              >市场拥有1000万左右外卖骑手，电瓶车是外卖骑手开展送外卖工作最重要的工具。电池需求量巨大，租赁难管理，易丢失</span
            >
          </div>
          <div class="sean-div-img-cls sean-img3 flex-col">
            <img class="sean-img-cls" src="../assets/img/solutionBg3.png">
            <span class="sean-title">环卫车应用场景</span>
            <span class="sean-content-cls"
              >铅酸电池使用寿命低，运营成本高，覆盖面广，人员难管理</span
            >
          </div>
          <div class="sean-div-img-cls sean-img4 flex-col">
            <img class="sean-img-cls" src="../assets/img/solutionBg4.png">
            <span class="sean-title">租电应用场景</span>
            <span class="sean-content-cls"
              >对于骑手等需要频繁使用电动车的群体，由于流动性大，购买电池的需求大，投入的成本较高，电池充电等待时间漫长</span
            >
          </div>
        </div>
        <img
            class="cooperation_img_cls2 mgt50 magb50"
            referrerpolicy="no-referrer"
            src="../assets/img/solution2.png"
          />
        <div class="section_mesg flex-col justify-between">
          <span class="mesg_text">我们能提供哪些解决方案？</span>
          <div class="mesg_border flex-col" style="width: 285px;"></div>
        </div>
        <div class="group_solution flex-row" v-for="(item,index) in solutionList" :key="index">
          <div class="text-wrapper_9 flex-col"><span class="text-solution-no">NO.{{index+1}}</span></div>
          <div class="text-solution-div flex-col justify-between">
            <span class="text_solution-title">{{item.title}}</span>
            <span class="text_solution-content">
              {{item.content}}
            </span>
          </div>
        </div>
        <div class="section_mesg flex-col justify-between">
          <span class="mesg_text">产品技术服务与支持</span>
          <div class="mesg_border flex-col" style="width: 220px;"></div>
        </div>
        <div class="support-whole-div flex-row">
          <div class="flex-row support-div-all-cls">
            <img
              class="support-img-cls"
              referrerpolicy="no-referrer"
              src="../assets/img/solutionIcon1.png"
            />
            <div class="flex-col support-div-cls">
              <span class="support-title-cls">安装指导</span>
              <span class="support-content-cls">
                我们的专业团队将为您提供详细的安装指导，确保您的设备在您的环境中成功安装和运行。我们的目标是让您的设备在尽可能短的时间内投入使用，产生效益。
              </span>
            </div>
          </div>
          <div class="flex-row support-div-all-cls">
            <img
              class="support-img-cls"
              referrerpolicy="no-referrer"
              src="../assets/img/solutionIcon2.png"
            />
            <div class="flex-col support-div-cls">
              <span class="support-title-cls">维护指导</span>
              <span class="support-content-cls">
                我们会为您提供全面的设备维护指南，包括日常检查、定期维护和预防性维护等。通过我们的指导，您将能够有效地维护和保养您的设备，延长其使用寿命。
              </span>
            </div>
          </div>
          <div class="flex-row support-div-all-cls">
            <img
              class="support-img-cls"
              referrerpolicy="no-referrer"
              src="../assets/img/solutionIcon3.png"
            />
            <div class="flex-col support-div-cls">
              <span class="support-title-cls">疑难问题</span>
              <span class="support-content-cls">
                我们的疑难问题解答服务旨在帮助您解决最棘手的问题，无论是在设备使用过程中遇到的复杂问题，还是需要深入了解设备的技术问题。我们的团队将通过专业的知识和经验，以最快的速度为您提供解决方案。
              </span>
            </div>
          </div>
        </div>
      </div>
      <bottom :activeName="activeName"/>
    </div>
  </div>
</template>
<script>
import Bottom from '../components/bottom.vue';
import Bread from '../components/bread.vue';
import navbar from '../components/navbar.vue';
export default {
  components: { navbar, Bottom, Bread },
  data() {
    return {
      activeName:'解决方案',
      solutionList:[]
    };
  },
  created(){
    this.initData()
  },
  methods: {
    initData(){
      this.solutionList=[
        {
          title:'电池管理',
          content:'通过电池内部的物联网模块，我们能够借助智慧管理平台进行精细化的锂电池管理，实时查看并掌握锂电池的工作状态，从而实现更高效、更便捷的电池管理。'
        },
        {
          title:'电池运营',
          content:'我们自主研发了蓄巨人运营管理系统，该系统能够实时监控锂电池的流转状态，实现精细化的运营管理，从而提高运营效率，使运营更加轻松便捷。'
        },
        {
          title:'电池定位',
          content:'智能定位信号无忧，GPS定位天线与铁箱提手两用，可无缝对接各种硬件保护版，具备远程控制断电功能，无需额外增加继电器，使定位更加便捷、高效、安全。'
        },
        {
          title:'电池价格',
          content:'价格优惠，低至1.5元/天/组起，无需一次性投入大量资金，减轻您的经济压力，让您享受更优惠的价格和更轻松的投入。'
        },
        {
          title:'明小租APP',
          content:'环卫设备车辆、电池租赁平台，为您提供方便快捷的租赁平台。'
        }
      ]
    }
  }
};
</script>
<style scoped>

.section_about {
  background-image: url(../assets/img/solution1.png);
  background-size: 100% 100%;
  height: 480px;
  width: 100%;
}
.group_1 {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 480px;
}
.about_title_cls {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  text-align: center;
  white-space: nowrap;
  line-height: 45px;
  margin: 220px auto 0 auto;
}
.section_3 {
  background-color: rgba(255, 255, 255, 1);
  width: 48px;
  height: 2px;
  margin: 20px auto 156px auto;
}


.cooperation-content-div-cls{
  width: 70%;
  min-width: 1024px;
  margin: 0 auto;
}
.section_mesg {
  box-shadow: inset 0px -1px 0px 0px rgba(234, 234, 234, 1);
  background-color: rgba(255, 255, 255, 1);
  margin: 100px 0 0 0;
}
.mesg_text {
  width: 96px;
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 33px;
}
.mesg_border {
  background-color: rgba(207, 162, 94, 1);
  width: 96px;
  height: 2px;
  margin-top: 18px;
}
.industry-div-cls{
  margin:40px 0;
  font-size: 15px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
}

.cooperation_img_cls2{
  width: 90%;
  margin-left: 5%;
}
.mgt50{
  margin-top:50px;
}
.magb50{
  margin-bottom: 50px;
}


.group_solution {
  box-shadow: inset 0px -1px 0px 0px rgba(234, 234, 234, 1);
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  height: 107px;
  margin: 40px 0 0 0;
}
.text-wrapper_9 {
  border-radius: 50%;
  height: 87px;
  border: 2px solid rgba(207, 162, 94, 1);
  width: 87px;
}

.text-solution-no {
  width: 36px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 22px;
  margin: 32px 0 0 26px;
}

.text-solution-div {
  margin-left: 50px;
  height: 82px;
}

.text_solution-title {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}

.text_solution-content {
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  margin-top: 20px;
}



.support-whole-div {
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
  justify-content: space-between;
}

.support-title-cls{
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin-left: 30px;
}

.support-content-cls{
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  margin-left: 30px;
}

.support-div-all-cls{
  width: 30%;
}
.support-div-cls{
  width:calc( 100% - 50px );
}
.support-img-cls {
  width: 38px;
  height: 38px;
}


.grid_sean-div{
  margin-top:40px;
  flex-wrap: wrap;
}
.sean-div-img-cls {
  width: calc(50% - 10px);
  position: relative;
}
.sean-img-cls{
  width: 100%;
}
.sean-img2 {
  margin-left: 20px;
}
.sean-img3 {
  margin-top:20px;
}
.sean-img4 {
  margin-left: 20px;
  margin-top:20px;
}
.sean-title {
  width: 108px;
  height: 25px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 18px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  position: absolute;
  top: 80px;
  right: 80px;
}
.sean-content-cls {
  width: 70%;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  text-align: right;
  line-height: 20px;
  position: absolute;
  right: 80px;
  top: 180px;
}
</style>