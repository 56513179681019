<template>
    <div class="navbar-section">
        <div class="navbar-cls flex-row justify-between">
            <div class="navbar-logo-cls flex-row justify-between">
                <img
                    class="navbar-image-cls"
                    referrerpolicy="no-referrer"
                    src="../assets/img/logo.png"
                />
                <div class="navbar-logo-div-cls flex-col justify-between">
                    <span class="logo-text1">加睿博智能科技</span>
                    <span class="logo-text2">ICROBO&nbsp;Intelligent&nbsp;Technology</span>
                </div>
            </div>
            <div class="flex-row">
                <div class="nav-text-div" v-for="(item,index) in listArr" :key="index" @click="clickNav(item)">
                    <div class="nav-text" :class="{'active':item.name===localActiveName}">{{item.name}}</div>
                    <div class="active-box" v-if="item.name===localActiveName"></div>
                </div>
                <a class="tel" href="tel:18019632666" title="18019632666">18019632666</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
        listArr:[{
            name:'首页',
            url:'/index'
        },{
            name:'产品与服务',
            url:'/productService'
        },{
            name:'解决方案',
            url:'/solution'
        },{
            name:'新闻动态',
            url:'/news'
        },{
            name:'关于我们',
            url:'/about'
        },{
            name:'合作招商',
            url:'/cooperation'
        }],
        localActiveName: this.activeName
    }
  },
  props:{
    activeName: {
      type: String,
      default: '首页'
    }
  },
  watch: {  
    activeName(newVal) {  
      this.localActiveName = newVal;  
    }  
  },
  methods:{
    clickNav(item){
        if(this.localActiveName !== item.name){
            this.$router.push({
                path: item.url
            })
        }
    }
  }
}
</script>
<style scoped>
    .navbar-logo-cls{
        margin-top:20px;
    }
    .navbar-section{
        background-color: rgba(0, 0, 0, 0.4);
        height: 80px;
        width: 100%;
        position: fixed;
        z-index: 8;
    }
    .navbar-cls{
        height: 44px;
        width: 70%;
        margin: 0 auto;
        min-width: 1024px;
    }
    .navbar-image-cls{
        width: 40px;
        height: 40px;
        margin-top: 3px;
    }
    .navbar-logo-div-cls{
        margin-left: 8px;
        height: 44px;
    }
    .logo-text1{
        height: 30px;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 24px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        text-align: left;
        white-space: nowrap;
        line-height: 30px;
        font-weight: 700;
    }
    .logo-text2{
        height: 16px;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 13px;
        text-align: left;
        white-space: nowrap;
        line-height: 16px;
    }
    .nav-text{
        overflow-wrap: break-word;
        color: rgba(191, 191, 191, 1);
        font-size: 18px;
        white-space: nowrap;
        line-height: 20px;
        margin: 30px 0 0 0;
        font-weight: 700;
    }
    .active{
        color:rgba(249, 213, 154, 1);
    }
    .active-box {
        background-color: rgba(249, 213, 154, 1);
        width: 56px;
        height: 4px;
        margin: 0 auto;
        margin-top: 26px;
    }
    .nav-text-div{
        width:110px;
        text-align: center;
        cursor: pointer;
        height: 80px;
    }
    .tel {
        margin-top: 28px;
        height: 24px;
        line-height: 24px;
        font-size: 24px;
        color: #F8B500;
        padding-left: 24px;
        background: url(../assets/img/tel.png) left center no-repeat;
        display: inline-block;
        font-family: Arial;
        position: absolute;
        right: calc( 25% - 400px );
    }
    @media (max-width:1700px) {
        .tel {
            right: 50px;
        }
    }
    @media (max-width:1400px) {
        .tel {
            position: relative !important;
            margin-left: 50px;
            right: auto;
        }
    }
</style>