<template>
    <div class="box_bottom flex-row justify-between">
        <div class="section_left flex-col">
          <div class="image-div-cls flex-row justify-between">
            <img
              class="image_bottom_cls"
              referrerpolicy="no-referrer"
              src="../assets/img/logo.png"
            />
            <div class="text-group-left-div-cls flex-col justify-between">
              <span class="company-bottom-cls">加睿博智能科技</span>
              <span class="company-bottom-desc">ICROBO&nbsp;Intelligent&nbsp;Technology</span>
            </div>
          </div>
          <div class="text-group-left-bottom flex-col justify-between">
            <span class="company-text-cls">©&nbsp;2024&nbsp;苏州加睿博智能科技有限公司&nbsp;版权所有</span>
            <span class="company-desc-cls">备案号：苏ICP备20016621号-1</span>
          </div>
        </div>
        <div class="group_bottom_right flex-col justify-between">
            <div class="text-wrapper-right-cls flex-row justify-between">
                <span class="nav-bottom-cls">快捷导航</span>
                <span class="nav-bottom-cls">联系我们</span>
            </div>
            <div class="group_bottom_cls flex-row justify-between">
                <div class="active-split flex-col">
                    <div class="bottom-item-cls" v-for="(item,index) in listArr" :key="index" @click="clickNav(item)">{{item.name}}</div>
                </div>
                <div class="active-split flex-col">
                    <div class="flex-row bottom-right-cls"><span class="text-label-text">电话：</span><span class="text-label-text-right">18019632666</span></div>
                    <div class="flex-row bottom-right-cls"><span class="text-label-text">邮箱：</span><span class="text-label-text-right">shiyuexiang@chinamingnuo.com</span></div>
                    <div class="flex-row bottom-right-cls"><span class="text-label-text">地址：</span><span class="text-label-text-right">江苏省苏州市相城区高铁新城南天成路99号清华紫光大厦5楼</span></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  data() {
    return {
        listArr:[{
            name:'产品与服务',
            url:'productService'
        },{
            name:'解决方案',
            url:'solution'
        },{
            name:'新闻动态',
            url:'news'
        },{
            name:'关于我们',
            url:'about'
        },{
            name:'合作招商',
            url:'cooperation'
        }],
        localActiveName:this.activeName
    }
  },
  props:{
    activeName: {
      type: String,
      default: '首页'
    }
  },
  watch: {  
    activeName(newVal) {  
      this.localActiveName = newVal;  
    }  
  },
  methods:{
    clickNav(item){
      if(this.localActiveName !== item.name){
        this.$router.push({
            path: item.url
        })
      }
    }
  }
}
</script>
<style scoped>
.bottom-item-cls{
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #AAAAAA;
    line-height: 22px;
    cursor: pointer;
    margin: 10px 0;
}
.text-label-text {
  width: 50px;
  overflow-wrap: break-word;
  color: rgba(170, 170, 170, 1);
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
}
.text-label-text-right {
  /* width: 213px; */
  overflow-wrap: break-word;
  color: rgba(170, 170, 170, 1);
  font-size: 16px;
  text-align: left;
  line-height: 22px;
}
.bottom-right-cls{
  margin: 10px 0;
}
.box_bottom {
  background-color: rgba(43, 42, 40, 1);
  height: 367px;
  width: 100%;
  min-width: 1024px;
  margin: 100px 0 1px 0;
  padding: 0 10%;
}

.section_left {
  height: 87px;
  margin: 70px auto 0 auto;
}

.image-div-cls {
  width: 381px;
  height: 87px;
}

.image_bottom_cls {
  width: 76px;
  height: 76px;
  margin-top: 6px;
}

.text-group-left-div-cls {
  width: 294px;
  height: 87px;
}

.company-bottom-cls {
  width: 294px;
  height: 59px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 42px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 59px;
}

.company-bottom-desc {
  width: 287px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(170, 170, 170, 1);
  font-size: 20px;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}

.group_bottom_right {
  box-shadow: inset 0px -1px 0px 0px rgba(255, 255, 255, 0.12);
  background-color: rgba(43, 42, 40, 1);
  height: 41px;
  width: 610px;
  margin-top: 70px;
  margin-left: 40px;
}

.text-wrapper-right-cls {
  width: 321px;
  height: 28px;
}

.nav-bottom-cls {
  width: 80px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}

.group_bottom_cls {
  width: 321px;
  height: 3px;
  margin-top: 10px;
}

.active-split {
  background-color: rgba(222, 187, 129, 1);
  width: 80px;
  height: 3px;
}

.active-split .bottom-item-cls:first-child{
    margin-top:20px;
}

.active-split .bottom-right-cls:first-child{
    margin-top:20px;
}

.text-group-left-bottom {
  width: 255px;
  height: 44px;
  margin-top: 60px;
}

.company-text-cls {
  width: 255px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(170, 170, 170, 1);
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
}

.company-desc-cls {
  width: 169px;
  height: 17px;
  overflow-wrap: break-word;
  color: rgba(170, 170, 170, 1);
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  margin-top: 10px;
}

</style>