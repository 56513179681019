<template>
  <div class="page flex-col">
    <div class="section_about flex-col">
      <div class="group_1 flex-col">
        <navbar :activeName="activeName"/>
        <span class="about_title_cls">{{activeName}}</span>
        <div class="section_3 flex-col"></div>
      </div>
    </div>
    <div class="box_16 flex-col">
      <bread :activeName="activeName"/>
      <div class="flex-row category-div-cls">
        <div class="category-text-cls" :class="{'active_category':activeCategory===item.id}" v-for="(item,index) in categoryList" :key="index" @click="clickCategory(item)">{{item.name}}</div>
      </div>
      <div class="product-content-div-cls flex-row justify-between">
        <img
          class="label_4"
          referrerpolicy="no-referrer"
          src="../assets/img/indexLeft.png"
          :class="{'disable-cls':productList.length<4}"
          @click="clickLeft"
        />
        <div class="grid_product flex-row">
          <div class="group_product_div flex-col" v-for="(item,index) in showProductList" :key="index" @click="clickProduct(item)">
            <div class="image-wrapper_2 flex-col">
              <img
                class="image_product"
                referrerpolicy="no-referrer"
                :src="baseURL+item.pic"
                :onerror="showErrorImage"
              />
            </div>
            <div class="group_product">
                {{item.title}}
            </div>
          </div>
        </div>
        <img
          class="product_icon_right"
          referrerpolicy="no-referrer"
          src="../assets/img/indexRight.png"
          :class="{'disable-cls':productList.length<4}"
          @click="clickRight"
        />
      </div>
      <div class="cooperation-content-div-cls">
        <img
            class="cooperation_img_cls mgt50 magb50"
            referrerpolicy="no-referrer"
            src="../assets/img/productService2.png"
          />
        <img
            class="cooperation_img_cls mgt50 magb50"
            referrerpolicy="no-referrer"
            src="../assets/img/productService3.png"
          />
      </div>
      <img
        class="cooperation_img_cls mgt50"
        referrerpolicy="no-referrer"
        src="../assets/img/productService4.png"
      />
      <div class="cooperation-content-cls">
        <div class="section_label flex-row justify-between">
          <img
            class="image_split_left"
            referrerpolicy="no-referrer"
            src="../assets/img/splite.png"
          />
          <div class="text-title-left-cls flex-col justify-between">
            <span class="title-english-text">BRAND</span>
            <div class="text-wrapper-cls">
              <span class="title-left-text">合作</span>
              <span class="title-right-text">品牌</span>
            </div>
          </div>
          <img
            class="image_split_right"
            referrerpolicy="no-referrer"
            src="../assets/img/splite.png"
          />
        </div>
      </div>
      <div class="product-brand-all-div-cls">
        <div class="product-brand-div-cls">
          <img v-for="(item,index) in brandList" :key="index" class="brand-image-cls" :src="baseURL+item.logo" />
        </div>
      </div>
      <bottom :activeName="activeName" style="margin-top:0px;"/>
    </div>
  </div>
</template>
<script>
import Bottom from '../components/bottom.vue';
import Bread from '../components/bread.vue';
import navbar from '../components/navbar.vue';
import { getCompanyData, getTypeList,getBrandList } from '@/api/home'
export default {
  components: { navbar, Bottom, Bread },
  data() {
    return {
      baseURL: this.$apis,
      activeName:'产品与服务',
      brandList:[],
      categoryList:[],
      activeCategory: '锂电池',
      productList:[],
      showProductList:[],
      showIndex:0,
      id: ''
    };
  },
  created(){
    this.id = parseInt(this.$route.query.id)
    this.initData()
  },
  computed: {
    showErrorImage() {
      return 'this.src="' + require('@/assets/img/productImg.png') + '"'
    },
  },
  methods: {
    clickCategory(item){
      this.activeCategory = item.id
      let obj={
        type:'product',
        status:'0',
        contain: item.id
      }
      this.getData(obj, 'product')
    },
    getData(params,type){
      getCompanyData(params).then(res => {
        if (res.code!==200) {
          this.$message.error(res.msg)
        } else {
          switch(type){
            case 'product':
              this.productList = []
              if(res.rows && res.rows.length>0){
                res.rows.forEach((item)=>{
                  item.remark = item.remark ? item.remark.replace(/<[^>]*>/g, '') : ''
                  this.productList.push(item)
                })
                this.showIndex=0
                let productList = JSON.parse(JSON.stringify(this.productList))
                this.showProductList = productList.splice(0,3)
              }
              break;
          }
          
        }
      })
    },
    clickLeft(){
      if(this.showIndex>0){
        this.showIndex--
        let productList = JSON.parse(JSON.stringify(this.productList))
        this.showProductList = productList.splice(this.showIndex, this.showIndex+3)
      }
    },
    clickRight(){
      console.log(this.showIndex)
      if(this.showIndex<(this.productList.length-3)){
        this.showIndex++
        console.log(this.showIndex,"ind")
        let productList = JSON.parse(JSON.stringify(this.productList))
        this.showProductList = productList.splice(this.showIndex, this.showIndex+3)
      }
    },
    initData(){
      getTypeList({type:'product'}).then(res => {
        if (res.code!==200) {
          this.$message.error(res.msg)
        } else {
          let categoryList = []
          res.rows.forEach(element => {
            if(element.status==='0'){
              categoryList.push(element)
            }
          });
          this.categoryList= categoryList
          if(this.id){
            this.activeCategory = this.id
            let obj={
              type:'product',
              status:'0',
              contain: this.id
            }
            this.getData(obj,'product')
          }else if(categoryList[0]){
            this.activeCategory = categoryList[0].id
            let obj={
              type:'product',
              status:'0',
              contain: categoryList[0].id
            }
            this.getData(obj,'product')
          }
        }
      })
      getBrandList({pageNum:1,pageSize:100}).then(res => {
        if (res.code!==200) {
          this.$message.error(res.msg)
        } else {
          console.log(res)
          this.brandList= res.rows
        }
      })
      // this.brandList = [
      //   {
      //     src:"./iconImg/编组 6.png"
      //   },
      //   {
      //     src:"./iconImg/编组 7.png"
      //   },
      //   {
      //     src:"./iconImg/编组 9.png"
      //   },
      //   {
      //     src:"./iconImg/编组 10.png"
      //   },
      //   {
      //     src:"./iconImg/编组 13.png"
      //   },
      //   {
      //     src:"./iconImg/编组 14.png"
      //   },
      //   {
      //     src:"./iconImg/编组 15.png"
      //   },
      //   {
      //     src:"./iconImg/编组 16.png"
      //   },
      //   {
      //     src:"./iconImg/编组 17.png"
      //   },
      //   {
      //     src:"./iconImg/编组 18.png"
      //   }, 
      //   {
      //     src:"./iconImg/编组 19.png"
      //   },
      //   {
      //     src:"./iconImg/编组 20.png"
      //   },
      //   {
      //     src:"./iconImg/编组 21.png"
      //   },
      //   {
      //     src:"./iconImg/编组 22.png"
      //   },
      //   {
      //     src:"./iconImg/编组 23.png"
      //   },
      //   {
      //     src:"./iconImg/编组 24.png"
      //   },
      //   {
      //     src:"./iconImg/编组 25.png"
      //   },
      //   {
      //     src:"./iconImg/编组 26.png"
      //   },
      //   {
      //     src:"./iconImg/编组 27.png"
      //   },
      //   {
      //     src:"./iconImg/编组 28.png"
      //   },
      //   {
      //     src:"./iconImg/编组 29.png"
      //   },
      //   {
      //     src:"./iconImg/编组 30.png"
      //   },
      //   {
      //     src:"./iconImg/编组 31.png"
      //   },
      //   {
      //     src:"./iconImg/编组 32.png"
      //   },
      //   {
      //     src:"./iconImg/编组 33.png"
      //   },
      //   {
      //     src:"./iconImg/编组 34.png"
      //   },
      //   {
      //     src:"./iconImg/编组 35.png"
      //   },
      //   {
      //     src:"./iconImg/编组 36.png"
      //   },
      //   {
      //     src:"./iconImg/编组 37.png"
      //   },
      //   {
      //     src:"./iconImg/编组 38.png"
      //   }, 
      //   {
      //     src:"./iconImg/编组 39.png"
      //   },
      //   {
      //     src:"./iconImg/编组 40.png"
      //   },   
      //   {
      //     src:"./iconImg/编组 42.png"
      //   },
      //   {
      //     src:"./iconImg/编组 43.png"
      //   },
      //   {
      //     src:"./iconImg/编组 44.png"
      //   },
      //   {
      //     src:"./iconImg/编组 45.png"
      //   },
      //   {
      //     src:"./iconImg/编组 46.png"
      //   },
      //   {
      //     src:"./iconImg/编组 47.png"
      //   },
      //   {
      //     src:"./iconImg/编组 48.png"
      //   }, 
      //   {
      //     src:"./iconImg/编组 49.png"
      //   },
      //   {
      //     src:"./iconImg/编组 50.png"
      //   },
      //   {
      //     src:"./iconImg/编组 51.png"
      //   }
      // ]
    },
    clickProduct(item){
      this.$router.push({
          path: 'productService/productServiceDetail?id='+item.id
      })
    }
  }
};
</script>
<style scoped>

.section_about {
  background-image: url(../assets/img/productService1.png);
  background-size: 100% 100%;
  height: 480px;
  width: 100%;
}
.group_1 {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 480px;
}
.about_title_cls {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  text-align: center;
  white-space: nowrap;
  line-height: 45px;
  margin: 220px auto 0 auto;
}
.section_3 {
  background-color: rgba(255, 255, 255, 1);
  width: 48px;
  height: 2px;
  margin: 20px auto 156px auto;
}


.mgt50{
  margin-top:50px;
}
.magb50{
  margin-bottom: 50px;
}
.cooperation-content-div-cls{
  width: 70%;
  min-width: 1024px;
  margin: 0 auto;
  margin-top: 50px;
}
.cooperation_img_cls{
  width: 100%;
}

/* 标题 */
.cooperation-content-cls{
  width: 100%;
  background: #f5f5f5;
  padding-bottom:50px;
}
.section_label {
  width: 248px;
  height: 78px;
  margin: 80px auto 0 auto;
}
.image_split_left {
  width: 30px;
  height: 1px;
  margin-top: 55px;
}
.image_split_right {
  width: 30px;
  height: 1px;
  margin: 55px 0 0 30px;
}
.text-title-left-cls {
  margin-left: 30px;
}
.title-english-text {
  height: 33px;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 24px;
  text-align: center;
  white-space: nowrap;
  line-height: 33px;
  margin-left: 2px;
}
.title-left-text {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(207, 162, 94, 1);
  font-size: 32px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 45px;
}
.title-right-text {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(34, 34, 34, 1);
  font-size: 32px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 45px;
}
.text-wrapper-cls {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 45px;
  margin-left: 1px;
}


.product-brand-all-div-cls{
  background: #f5f5f5;
  width: 100%;
  padding-bottom: 80px;
}
.product-brand-div-cls{
  width: 70%;
  min-width: 1024px;
  margin: 0 auto;
}
.brand-image-cls{
  width: 14%;
  margin: 0 auto;
  margin-top: -14px;
  overflow: hidden;
}


.category-div-cls{
  width: 70%;
  min-width: 1024px;
  margin: 0 auto;
  margin-top: -50px;
}
.category-text-cls{
  margin: 0 20px;
  text-align: center;
  height: 50px;
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  cursor: pointer;
}
.active_category{
  font-size: 24px;
  border-bottom: #DEBB81 solid 4px;
}

/* 产品 */
.product-content-div-cls{
  width: 70%;
  min-width: 1024px;
  margin: 0 auto;
  margin-top: 80px;
  padding: 0 25px;
}
.label_4 {
  width: 48px;
  height: 48px;
  margin-top: 70px;
  cursor: pointer;
}

.grid_product {
  margin-left: 39px;
  flex-wrap: wrap;
}

.image-wrapper_2 {
  box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.04);
  background-color: rgba(255, 255, 255, 1);
  height: 212px;
  margin: 0 20px;
  width: 260px;
}

.image-wrapper_2:nth-child(3n) {
  margin-right: 0;
}
.image-wrapper_2:nth-last-child(-n + 3) {
  margin-bottom: 0;
}

.image_product {
  height: 212px;
  margin: 0 auto;
}

.product_icon_right {
  width: 48px;
  height: 48px;
  margin: 70px 0 0 0px;
  cursor: pointer;
}
.group_product{
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 22px;
  text-align: center;
  width: 100%;
  margin-top: 20px;
}
.group_product_div{
  cursor: pointer;
}
.disable-cls{
  cursor: not-allowed;
}
</style>