<template>
  <div class="page flex-col">
    <div class="section_about flex-col">
      <div class="group_1 flex-col">
        <navbar :activeName="activeName"/>
        <span class="about_title_cls">{{activeName}}</span>
        <div class="section_3 flex-col"></div>
      </div>
    </div>
    <div class="box_16 flex-col">
      <bread :activeName="activeName"/>
      <div class="flex-row category-div-cls">
        <div class="category-text-cls" :class="{'active_category':activeCategory===item.id}" v-for="(item,index) in categoryList" :key="index" @click="clickCategory(item)">{{item.name}}</div>
      </div>
      <div class="news-content-div-cls">
        <div class="cooperation-content-div-cls flex-row" v-if="detailData.id">
          <div class="product-type-image-cls" @click="clickDetail(detailData)">
            <img :src="baseURL+detailData.pic" class="product-img-cls" :onerror="showNewsImage"/>
          </div>
          <div class="section_news_cls flex-col">
            <span class="news_title_cls" @click="clickDetail(detailData)">
              {{detailData.title | filterNull}}
            </span>
            <span class="news_content_cls">
              {{detailData.content | filterNull}}
            </span>
            <div class="box_12 flex-row justify-between">
              <span class="text_16">{{detailData.day | filterNull}}</span>
              <div class="box_8 flex-col"></div>
            </div>
            <span class="text_17">{{detailData.date | filterNull}}</span>
            <div class="box_13 flex-row justify-between">
              <div class="text-wrapper_2 flex-col" @click="clickDetail(detailData)"><span class="text_18">查看详情</span></div>
            </div>
            <div class="image-wrapper_5 flex-row ">
              <div class="flex-row">
                <div class="rotation-cls" v-for="(item,index) in rotationList" :key="index" :class="{'active-rotation':showRotation===index}" @click="clickRotation(index)"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="new-content-div-cls flex-row" v-for="(item,index) in newsList" :key="index" @click="clickDetail(item)">
          <div class="block_date flex-row">
            <div class="text-group_6 flex-col justify-between">
              <span class="text_date">{{item.day | filterNull}}</span>
              <span class="text_date1">{{item.date | filterNull}}</span>
            </div>
          </div>
          <div class="text-wrapper-content-cls flex-col justify-between">
            <span class="text_news_title">{{item.title | filterNull}}</span>
            <span class="text_news_content">
              {{item.content | filterNull}}
            </span>
          </div>
          <div class="image-wrapper_1 flex-col">
            <div class="image_arrow"></div>
          </div>
        </div>
        <div class="pagination-div-cls" v-if="newsList.length>0">
          <el-pagination
            class="pagination-cls"
            background
            prev-text="上一页"
            next-text="下一页"
            layout="prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange">
          </el-pagination>
        </div>
      </div>
      <bottom :activeName="activeName" style="margin-top:0px;"/>
    </div>
  </div>
</template>
<script>
import Bottom from '../components/bottom.vue';
import Bread from '../components/bread.vue';
import navbar from '../components/navbar.vue';
import { getTypeList, getCompanyData} from '@/api/home'
export default {
  components: { navbar, Bottom, Bread },
  data() {
    return {
      baseURL: this.$apis,
      activeName:'新闻动态',
      categoryList:[],
      activeCategory: '公司新闻',
      newsList:[],
      rotationList:[],
      showRotation:0,
      total:0,
      detailData:{
        title:'',
        day:'',
        date:'',
        remark:''
      },
      obj:{},
      id: null
    };
  },
  computed: {
    showNewsImage() {
      return 'this.src="' + require('@/assets/img/news2.png') + '"'
    },
  },
  filters:{
    filterNull(val){
      if(val){
        return val
      }else{
        return ''
      }
    }
  },
  created(){
    this.id = parseInt(this.$route.query.id)
    this.initData()
  },
  methods: {
    clickRotation(index){
      this.showRotation = index
      this.detailData = this.rotationList[index]
    },
    clickCategory(item){
      this.activeCategory = item.id
      this.obj={
        type:'news',
        status:'0',
        columnName: item.name,
        pageNum:1,
        pageSize:10
      }
      this.getData(this.obj,'news')
    },
    getData(params,type){
      getCompanyData(params).then(res => {
        if (res.code!==200) {
          this.$message.error(res.msg)
        } else {
          switch(type){
            case 'company':
              if(res.rows)
                this.company = res.rows[0]
                this.company.remark = this.company.remark ? this.company.remark.replace(/<[^>]*>/g, ''):''
              break;
            case 'brand':
              if(res.rows)
                this.brand = res.rows[0]
                this.brand.remark = this.brand.remark ? this.brand.remark.replace(/<[^>]*>/g, ''): ''
              break;
            case 'product':
              this.productList = []
              if(res.rows && res.rows.length>0){
                res.rows.forEach((item)=>{
                  item.remark = item.remark ? item.remark.replace(/<[^>]*>/g, ''): ''
                  this.productList.push(item)
                })
                this.showIndex=0
                let productList = JSON.parse(JSON.stringify(this.productList))
                this.showProductList = productList.splice(0,3)
              }
              break;
            case 'news':
              if(res.rows){
                this.newsList = []
                this.rotationList = []
                this.detailData={
                  title:'',
                  day:'',
                  date:'',
                  remark:''
                }
                res.rows.forEach((item,index)=>{
                  if(index===0 || index===1 || index===2){
                    let date = item.createTime.split(" ")
                    item.date = date[0].slice(0,4)
                    item.day = date[0].slice(5,10)
                    if(index===0 ){
                      this.detailData = item
                    }
                    this.rotationList.push(item)
                  }else{
                    let date = item.createTime.split(" ")
                    item.date = date[0].slice(0,7)
                    item.day = date[0].slice(8,10)
                    this.newsList.push(item)
                  }
                })
                this.total = res.total
              }
          }
          
        }
      })
    },
    handleCurrentChange(val){
      this.obj.pageNum = val
      this.getData(this.obj,'news')
    },
    initData(){
      getTypeList({type:'news'}).then(res => {
        if (res.code!==200) {
          this.$message.error(res.msg)
        } else {
          let categoryList = []
          let name = ''
          res.rows.forEach(element => {
            if(element.status==='0'){
              if(this.id==element.id){
                name = element.name
              }
              categoryList.push(element)
            }
          });
          this.categoryList= categoryList
          if(this.id){
            this.activeCategory = this.id
            let obj={
              type:'news',
              status:'0',
              columnName: name,
              pageNum:1,
              pageSize:10
            }
            this.getData(obj,'news')
          }else if(categoryList[0]){
            this.activeCategory = categoryList[0].id
            let obj={
              type:'news',
              status:'0',
              columnName: categoryList[0].name,
              pageNum:1,
              pageSize:10
            }
            this.getData(obj,'news')
          }
          // if(categoryList[0]){
          //   this.activeCategory = categoryList[0].name
          //   this.obj={
          //     type:'news',
          //     status:'0',
          //     columnName: categoryList[0].name,
          //     pageNum:1,
          //     pageSize:10
          //   }
          //   this.getData(this.obj,'news')
          // }
        }
      })
    },
    clickDetail(e){
      this.$router.push({
          path: 'news/newsDetail?id='+e.id
      })
    },
  }
};
</script>
<style scoped>

.section_about {
  background-image: url(../assets/img/news1.png);
  background-size: 100% 100%;
  height: 480px;
  width: 100%;
}
.group_1 {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 480px;
}
.about_title_cls {
  width: 128px;
  height: 45px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 32px;
  text-align: center;
  white-space: nowrap;
  line-height: 45px;
  margin: 220px auto 0 auto;
}
.section_3 {
  background-color: rgba(255, 255, 255, 1);
  width: 48px;
  height: 2px;
  margin: 20px auto 156px auto;
}

.category-div-cls{
  width: 70%;
  min-width: 1024px;
  margin: 0 auto;
  margin-top: -50px;
}
.category-text-cls{
  margin: 0 20px;
  text-align: center;
  height: 50px;
  font-size: 16px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  cursor: pointer;
}
.active_category{
  font-size: 24px;
  border-bottom: #DEBB81 solid 4px;
}


.product-type-image-cls{
  width: 522px;
  background: #FFFFFF;
  cursor: pointer;
}
.product-img-cls{
  width: 100%;
  min-height: 300px;
}
.news-content-div-cls{
  background: #F5F5F5;
  min-height: 300px;
}
.cooperation-content-div-cls {
  width: 70%;
  min-width: 1024px;
  margin: 0 auto;
  margin-top: 50px;
  background: #fff;
}

.section_news_cls {
  width: calc( 100% - 640px);
  margin: 40px 60px 40px 60px;
  position: relative;
}

.news_title_cls {
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  text-align: left;
  line-height: 28px;
  cursor: pointer;
}
.news_content_cls{
  overflow-wrap: break-word;
  font-size: 15px;
  text-align: left;
  line-height: 28px;
  height: 54px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px;
  color: rgba(119, 119, 119, 1);
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.box_12 {
  width: 368px;
  height: 28px;
  margin-top: 20px;
}

.text_16 {
  width: 58px;
  height: 28px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  text-align: left;
  white-space: nowrap;
  line-height: 28px;
}

.box_8 {
  box-shadow: inset 0px -1px 0px 0px rgba(238, 238, 238, 1);
  width: 290px;
  height: 1px;
  margin-top: 13px;
}

.text_17 {
  width: 39px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin-top: 4px;
}

.box_13 {
  width: 368px;
  height: 40px;
  position: absolute;
  bottom: 0;
}

.text-wrapper_2 {
  background-color: rgba(222, 187, 129, 1);
  height: 40px;
  width: 124px;
  cursor: pointer;
}

.text_18 {
  width: 64px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 9px 0 0 30px;
  cursor: pointer;
}

.new-content-div-cls {
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  width: 70%;
  min-width: 1024px;
  margin: 0 auto;
  margin-top: 50px;
  background: #fff;
  cursor: pointer;
}

.block_date {
  background: rgba(239,170,71,0.18);
  width: 174px;
  height: 160px;
}

.text-group_6 {
  width: 76px;
  height: 106px;
  margin: 27px 0 0 49px;
}

.text_date {
  width: 68px;
  height: 78px;
  overflow-wrap: break-word;
  color: #CFA25E;
  font-size: 56px;
  text-align: left;
  white-space: nowrap;
  line-height: 78px;
  margin-left: 4px;
}

.text_date1 {
  width: 76px;
  height: 25px;
  overflow-wrap: break-word;
  color: #CFA25E;
  font-size: 18px;
  text-align: left;
  white-space: nowrap;
  line-height: 25px;
  margin-top: 3px;
}

.text-wrapper-content-cls {
  width: calc( 100% - 360px );
  height: 100px;
  margin: 30px 0 0 30px;
}

.text_news_title {
  width: 100%;
  height: 30px;
  overflow-wrap: break-word;
  color: #333;
  font-size: 22px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_news_content {
  width: 100%;
  height: 40px;
  overflow-wrap: break-word;
  color: rgba(119, 119, 119, 1);
  font-size: 14px;
  text-align: left;
  line-height: 20px;
  margin-top: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-wrapper_1 {
  box-shadow: inset 1px 0px 0px 0px rgba(238, 238, 238, 1);
  background-color: rgba(255, 255, 255, 1);
  height: 112px;
  width: 120px;
  margin: 24px 0 0 28px;
}

.image_arrow {
  width: 53px;
  height: 10px;
  margin: 51px 0 0 34px;
  background: url(../assets/img/arrowNone.png);
}

.new-content-div-cls:hover .block_date{
  background: rgba(222, 187, 129, 1);
}

.new-content-div-cls:hover .text_date,.new-content-div-cls:hover .text_date1{
  color: rgba(255, 255, 255, 1);
}
.new-content-div-cls:hover .text_news_title{
  color: rgba(207, 162, 94, 1);
}
.new-content-div-cls:hover .image_arrow{
  background: url(../assets/img/active.png);
}



.pagination-div-cls{
  margin: 50px auto;
  box-shadow: inset 0px 1px 0px 0px #d7d7d7;
  padding-top: 50px;
  width: 70%;
}
.pagination-cls{
  margin: 0 auto;
  text-align: center;
}

.image-wrapper_5 {
  width: 100%;
  position: absolute;
  bottom: 0;
  justify-content: flex-end;
}
.rotation-cls{
  border-radius:50%;
  background: #999;
  width: 10px;
  height: 10px;
  margin:0 5px;
  cursor: pointer;
}
.active-rotation{
  background: #CFA25E;
}
</style>